<template>
    <div class="key__filter__tag__inner">
        <span :id="'rule-'+rule.id" @click="ruleClicked">
            <template v-if="rule.label !== 'Keyword'">
                <template v-if="typeof filterNames[rule.label] !== 'undefined'">{{ filterNames[rule.label] }}</template>
                <template v-else>{{rule.label}}</template> |
            </template>

            {{operatorAlias}}

            <template v-if="typeof rule.groupWords !== 'undefined' && rule.groupWords.length">"{{ rule.valueAlias }}" ({{rule.groupWords.length + 1}} keywords)</template>
            <template v-else>{{ rule.valueAlias }}</template>
        </span>

        <span v-if="!readOnly" class="key__filter__close" @click.stop="deleteRule"></span>

        <b-popover v-if="rule.name === 'keyword' && !readOnly && !this.rule.groupWords.length" ref="rulePopover" :target="'rule-'+rule.id" custom-class="popover-sm">
            <div class="popover__content" v-click-outside="closeRulePopover">
                <ul class="simple-list simple-list--sm">
                    <li>
                        <a v-if="!rule.negative" @click="invertRule">Exclude word from search</a>
                        <a v-else @click="invertRule">Include word to search</a>
                    </li>

                    <li><a @click="editGroup">Edit keyword group</a></li>
                </ul>
            </div>
        </b-popover>
    </div>
</template>

<script>
    export default {
        props: {
            rule: Object,

            readOnly: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                filterNames: {
                    'fundingSeries': "Funding Series",
                    'fundingRange': "Funding Range",
                    'revenueRange': "Revenue Range",
                    'employeeCount': "Employee Count",
                    'lastFundingDate': "Last Funding Date",
                    'foundedYear': "Founded Year",
                    'lastInteraction': "Last Interaction",
                    'tags': "Tags",
                    'categories': "Categories",
                    'locations': "Locations",
                    'ecosystems': "Ecosystems",
                    'clients': "Clients",
                    'trackers': "Trackers",
                    'investors': "Investors",
                    'ecosystemTypes': "Type",
                },
            }
        },

        computed : {
            operatorAlias: function() {
                if (this.rule.negative) {
                    return '≠';
                }

                if (this.rule.operator === 'contains' || this.rule.operator === 'in') {
                    return '=';
                }

                if (this.rule.operator === 'greater_or_equal') {
                    return '≥';
                }

                if (this.rule.operator === 'less_or_equal') {
                    return '≤';
                }

                return this.rule.operator;
            },
        },

        methods: {
            queryFormStatus() {
                return {
                    'name'    : this.rule.name,
                    'operator': this.rule.operator,
                    'value'   : this.rule.value,
                    'score'   : this.rule.score,
                    'cat'     : this.rule.cat,
                    'subcat'  : this.rule.subcat
                }
            },

            fillRuleStatus(data) {
                this.rule.name = data.name;
                this.rule.cat  = data.cat;
                this.rule.operator = data.operator;
                this.rule.value = data.value;

                if (typeof data.subcat !== 'undefined') {
                    this.rule.subcat = data.subcat;
                }
            },

            deleteRule() {
                // delete-rule
                this.$emit('delete-rule', this.rule);
            },

            invertRule() {
                this.$emit('invert-rule', this.rule);
                this.closeRulePopover()
            },

            closeRulePopover() {
                this.$refs.rulePopover.$emit('close')
            },

            editGroup() {
                this.$emit('edit-group', this.rule);
                this.closeRulePopover()
            },

            ruleClicked () {
                if (this.rule.name !== 'keyword') {
                    this.$emit('invert-rule', this.rule);
                } else if (this.rule.groupWords.length) {
                    this.$emit('edit-group', this.rule);
                }
            }
        },
    }
</script>
